import React from 'react';
import {Link} from 'react-router-dom';
import './Header.scss';
import logo from '../../assets/logo.svg';

const Header: React.FC = () => (
  <header>
    <div>
      <img src={logo} alt="Logo Riddle" />
        <ul>
            <Link to="/">Accueil</Link>
            {/*<Link to="/ads">Riddle Ads</Link>*/}
            <Link to="/contact">Contact</Link>
        </ul>
    </div>
  </header>
);

export default Header;