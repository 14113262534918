import React from 'react';
import './Footer.scss';
import {Link} from "react-router-dom";

const Footer: React.FC = () => (
  <footer>
    <div className="container">
      <p className="copyright">RIDDLE © 2024</p>
      <div className="links">
        <Link to="/privacy">Politique de confidentialité</Link>
        <Link to="/CGU">CGU</Link>
      </div>
    </div>
  </footer>
);

export default Footer;