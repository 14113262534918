import React from 'react';
import './Legal.scss';
import logo from "../../assets/logo.svg";
import {ChevronLeft, Mail} from "lucide-react";
import {Link} from "react-router-dom";

const TermsOfService: React.FC = () => (
    <div className="view">
        <div className="top">
            <Link to="/">
                <ChevronLeft color="white" size={40} strokeWidth={2}/>
            </Link>
            <img src={logo} alt="Logo Riddle"/>
            <h1>Conditions Générales d'Utilisation</h1>
        </div>

        <div className="content">
            <section>
                <h2>1. Introduction</h2>
                <p>
                    Les présentes Conditions Générales d'Utilisation (CGU) régissent l'utilisation de la plateforme
                    Riddle
                    (ci-après "Riddle" ou "la Plateforme"). En vous inscrivant et en utilisant Riddle, vous acceptez
                    sans réserve
                    les présentes CGU. Si vous n'acceptez pas ces termes, vous ne devez pas utiliser la Plateforme.
                </p>
            </section>

            <section>
                <h2>2. Inscription et Compte Utilisateur</h2>

                <h3>2.1. Création du compte</h3>
                <p>
                    Pour accéder aux services offerts par Riddle, l'utilisateur doit créer un compte en fournissant des
                    informations
                    exactes, complètes et à jour. L'utilisateur s'engage à ne fournir que des informations véridiques
                    lors de l'inscription.
                </p>

                <h3>2.2. Responsabilité du compte</h3>
                <p>
                    L'utilisateur est seul responsable de la sécurité de ses identifiants de connexion (nom
                    d'utilisateur, mot de passe).
                    Toute activité effectuée à partir du compte utilisateur est réputée être effectuée par
                    l'utilisateur, qui en assume
                    l'entière responsabilité. En cas de suspicion d'utilisation non autorisée de son compte,
                    l'utilisateur doit en informer
                    immédiatement Riddle.
                </p>

                <h3>2.3. Âge minimum</h3>
                <p>
                    L'utilisation de Riddle est strictement réservée aux personnes âgées de 14 à 23 ans. Tout
                    utilisateur ne respectant pas
                    cette tranche d'âge est invité à ne pas s'inscrire ou à cesser d'utiliser Riddle. Riddle se réserve
                    le droit de vérifier
                    l'âge des utilisateurs et de prendre les mesures nécessaires en cas de violation de cette règle.
                </p>
            </section>

            <section>
                <h2>3. Utilisation de Riddle</h2>

                <h3>3.1. Contenu</h3>
                <p>
                    Les utilisateurs peuvent publier, partager, commenter et interagir avec du contenu sous différentes
                    formes
                    (texte, images, vidéos, etc.). Les utilisateurs sont responsables de tout le contenu qu'ils publient
                    sur la Plateforme.
                    Ils s'engagent à respecter les lois en vigueur, y compris celles relatives aux droits d'auteur, à la
                    diffamation,
                    et à la protection de la vie privée.
                </p>

                <h3>3.2. Comportement prohibé</h3>
                <p>
                    Il est formellement interdit de publier sur Riddle tout contenu illégal, offensant, diffamatoire,
                    incitant à la haine ou
                    à la violence, ou portant atteinte aux droits d'autrui, y compris les droits de propriété
                    intellectuelle et le droit
                    à l'image. Les utilisateurs doivent s'abstenir de toute forme de harcèlement, d'intimidation, ou de
                    comportements
                    malveillants envers d'autres utilisateurs.
                </p>

                <h3>3.3. Modération</h3>
                <p>
                    Riddle se réserve le droit de surveiller, modérer et supprimer tout contenu publié sur la Plateforme
                    qui ne serait pas
                    conforme aux présentes CGU ou aux lois en vigueur. Riddle se réserve également le droit de suspendre
                    ou de résilier un
                    compte utilisateur en cas de non-respect des règles définies dans les présentes CGU.
                </p>
            </section>

            <section>
                <h2>4. Protection des Données Personnelles</h2>

                <h3>4.1. Collecte des données</h3>
                <p>
                    Riddle collecte certaines données personnelles des utilisateurs nécessaires à l'inscription et à
                    l'utilisation des
                    services proposés par la Plateforme. Ces données peuvent inclure le nom, l'âge, l'adresse e-mail,
                    ainsi que d'autres
                    informations pertinentes fournies lors de l'inscription.
                </p>

                <h3>4.2. Utilisation des données</h3>
                <p>
                    Les données collectées par Riddle sont utilisées principalement pour la gestion des comptes
                    utilisateurs, la
                    personnalisation des services, et, avec l'accord préalable de l'utilisateur, à des fins de
                    communication marketing.
                    Riddle s'engage à ne pas partager les données personnelles des utilisateurs avec des tiers sans leur
                    consentement
                    explicite, sauf dans les cas prévus par la loi.
                </p>

                <h3>4.3. Droits des utilisateurs</h3>
                <p>
                    Conformément à la législation en vigueur, les utilisateurs disposent de droits d'accès, de
                    rectification, de
                    suppression, et de portabilité de leurs données personnelles. Les utilisateurs peuvent exercer ces
                    droits en
                    contactant Riddle à l'adresse suivante : [email/contact].
                </p>
            </section>

            <section>
                <h2>5. Propriété Intellectuelle</h2>

                <h3>5.1. Droits de propriété</h3>
                <p>
                    Riddle et ses concédants de licence détiennent tous les droits de propriété intellectuelle sur la
                    Plateforme,
                    y compris mais sans s'y limiter, les logiciels, la conception, les marques, et le contenu généré par
                    Riddle.
                    Aucun élément de la Plateforme ne peut être utilisé, reproduit ou distribué sans l'autorisation
                    écrite préalable
                    de Riddle.
                </p>

                <h3>5.2. Licence d'utilisation</h3>
                <p>
                    En publiant du contenu sur Riddle, l'utilisateur accorde à Riddle une licence non exclusive,
                    mondiale, gratuite et
                    transférable pour utiliser, reproduire, modifier, adapter, publier et distribuer ce contenu dans le
                    cadre du
                    fonctionnement et de la promotion du service. Cette licence perdure tant que le contenu reste
                    accessible sur la Plateforme.
                </p>
            </section>

            <section>
                <h2>6. Limitation de Responsabilité</h2>

                <h3>6.1. Contenu des utilisateurs</h3>
                <p>
                    Riddle décline toute responsabilité quant au contenu publié par les utilisateurs sur la Plateforme.
                    Les utilisateurs
                    sont seuls responsables de leurs publications et en assument toutes les conséquences légales.
                </p>

                <h3>6.2. Indisponibilité du service</h3>
                <p>
                    Riddle met tout en œuvre pour assurer la disponibilité et la qualité du service. Cependant, Riddle
                    ne garantit pas
                    l'absence d'interruptions, de dysfonctionnements ou d'erreurs dans le fonctionnement de la
                    Plateforme. Riddle ne saurait
                    être tenu responsable des dommages directs ou indirects liés à l'indisponibilité du service.
                </p>
            </section>

            <section>
                <h2>7. Modification des CGU</h2>
                <p>
                    Riddle se réserve le droit de modifier les présentes CGU à tout moment. Les utilisateurs seront
                    informés de toute
                    modification substantielle par le biais de notifications sur la Plateforme ou par e-mail.
                    L'utilisation continue
                    de Riddle après notification des modifications constitue une acceptation des nouvelles CGU. Il est
                    de la responsabilité
                    de l'utilisateur de consulter régulièrement les CGU pour se tenir informé des éventuelles
                    modifications.
                </p>
            </section>

            <section>
                <h2>8. Résiliation</h2>

                <h3>8.1. Résiliation par l'utilisateur</h3>
                <p>
                    L'utilisateur peut résilier son compte à tout moment en suivant la procédure de désinscription
                    accessible sur Riddle.
                    Une fois le compte résilié, l'accès à Riddle et aux services associés sera immédiatement suspendu.
                </p>

                <h3>8.2. Résiliation par Riddle</h3>
                <p>
                    Riddle se réserve le droit de suspendre ou de résilier un compte utilisateur sans préavis en cas de
                    non-respect des
                    présentes CGU ou en cas d'activités suspectes ou frauduleuses. En cas de résiliation, l'utilisateur
                    recevra une
                    notification par e-mail.
                </p>
            </section>

            <section>
                <h2>9. Droit applicable et Juridiction</h2>
                <p>
                    Les présentes CGU sont régies par le droit français. En cas de litige concernant l'utilisation de Riddle, les parties
                    conviennent de se soumettre à la compétence exclusive des tribunaux de Paris.
                </p>
            </section>

            <section>
                <h2>10. Contact</h2>
                <p>
                    Pour toute question ou demande concernant les présentes CGU ou l'utilisation de Riddle, vous pouvez
                    contacter notre service de support à l'adresse suivante :
                </p>
                <a href="mailto:admin@app-riddle.com">
                    <Mail color="#885CF4" size={24} strokeWidth={2}/>
                    admin@app-riddle.com
                </a>
                <p>
                    Riddle s'engage à répondre aux demandes dans les plus brefs délais.
                </p>
            </section>
        </div>
    </div>
);

export default TermsOfService;