import React from 'react';
import { Link } from 'react-router-dom';
import './Errors.scss';

const Error500: React.FC = () => {
    return (
        <div className="error">
            <h1>Erreur 500</h1>
            <h2>Erreur du serveur</h2>
            <p>Une erreur est survenue sur le serveur. Veuillez réessayer plus tard.</p>
            <Link to="/" className="home-link">
                <button>Retourner à l'accueil</button>
            </Link>
        </div>
    );
};

export default Error500;