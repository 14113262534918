import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useEffect} from "react";

export const Referral = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();

    return (
        <div>
            COUCOUUU
        </div>
    );

}
