import {useLocation, useNavigate} from 'react-router-dom';
import {useEffect} from "react";

export const OpenApp = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        const searchParams = new URLSearchParams(location.search);
        const screen = searchParams.get('screen');
        const token = searchParams.get('token');

        console.log(searchParams);
        console.log('Screen:', screen);
        console.log('Token:', token);

        if (screen === 'ResetPassword' && token) {
            const deepLink = `riddle://resetPassword?token=${token}`;
            window.location.href = deepLink;

            setTimeout(() => {
                navigate("/");
            }, 2000);
        } else {
            navigate("/");
        }
    }, [location.search]);

    return (
        <div>
            <p>Redirection en cours...</p>
        </div>
    );

}
