import React from 'react';
import './Countdown.scss';

const Countdown: React.FC = () => {

    return (
        <div id="countdown">
            <p>Riddle est sorti, télécharge-la dès maintenant !</p>
        </div>
    );
};

export default Countdown;